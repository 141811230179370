import React, { useState } from "react";
import { useEffect } from "react";
import { getApi } from "../Api/AllApi";
// import imageData from "../../public/Assets/images/iphone-14.jpg"
import './shop.css'
import { baseUrl } from "../env";
import { Link, useNavigate, useParams, useSearchParams } from "react-router-dom";
let cartlist = []
let cartids = []

function Shop() {
    const navigate = useNavigate();
    const [userData, setUserData] = useState([])
    const [peoductList, setProductList] = useState(null)
    const [activeCat, setActiveCat] = useState(0)
    const [catDropdown, setCatDropdown] = useState(false)
    const [items, setItems] = useState([])
    let {vendorid}=useParams()
    let vendorId = vendorid || localStorage.getItem('vendorId')
    

    useEffect(() => {
        
    if (vendorId) {
        localStorage.setItem('vendorId', vendorId)
    } else {
        navigate('/urlnotfound')
    }
        getApi(vendorId)
            .then(res => {
                if (res.data.data) {
                    setUserData([...res.data.data]);
                    getProduct(res.data.data[0].productList, 0)
                }
            })

            .catch(err => console.log(err, 'error'))
    }, [])

    const addtoCart = (obj, i) => {
    
        if (obj.qty)
            obj.qty += 1
        else
            obj.qty = 1
        cartids = cartlist.map(i => i._id)
        const index = cartids.indexOf(obj._id)
        if (index >= 0) {
            cartlist[index] = obj
        } else {
            cartlist.push(obj)
        }
        localStorage.setItem('cart-'+vendorId, JSON.stringify(cartlist))
        setItems([...items])
        createUpdateEvent()

    }
    const createUpdateEvent = () => {
        const event = new Event('localstorage');
        event.key = 'test';
        event.value = 'hello';
        document.dispatchEvent(event);
    }
    const deleteCart = (obj, i) => {
        if (obj.qty) {
            if (obj.qty === 1) {
                obj.qty =null
                cartlist.splice(cartids.indexOf(obj.id), 1)
            } else {
                obj.qty -= 1
                cartlist[cartids.indexOf(obj.id)] = obj;
            }
            localStorage.setItem('cart-'+vendorId, JSON.stringify(cartlist))
            setItems([...items])
            createUpdateEvent()

        }

    }

    const getProduct = (products, i) => {
        setActiveCat(i)
        setCatDropdown(false)
        if (products && products.length) {
            const str = localStorage.getItem('cart-'+vendorId);
            cartlist = str ? JSON.parse(str) : [];
            cartids = cartlist.map(i => i._id)
            if (cartids.length) {
                products.forEach(item => {
                    const ind = cartids.indexOf(item._id)
                    if (ind >= 0) {
                        item.qty = cartlist[ind].qty
                    }
                })
            }
            setProductList([...products])
        }
        else {
            setProductList(null)
        }
    }

    const getDetails=(e,item)=>{
         e.preventDefault()
         navigate('/productdetails/'+item._id)
    }

    return (
        <div>
            <section className="hero hero-normal mcat">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="hero__categories">
                                <div className="hero__categories__all" onClick={()=>setCatDropdown(!catDropdown)}>
                                    <i className="fa fa-bars"></i>
                                    <span>All departments</span>
                                </div>
                                <ul style={{display:catDropdown?'block':'none'}}>
                                    {userData.map((item, i) => (
                                        <li key={'mcat' + i} className={`${(activeCat === i) ? 'catactive' : 'catbutton'}`} onClick={() => getProduct(item.productList, i)}><a >{item.name}</a></li>

                                    )
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3 col-md-5 dcat">
                            <div className="sidebar">
                                <div className="sidebar__item">
                                    <h4>Department</h4>
                                    <ul>
                                        {userData.map((item, i) => (
                                            <div className={`${(activeCat === i) ? 'catactive' : 'catbutton'}`} key={'dcat' + i} onClick={() => getProduct(item.productList, i)}>
                                                <li><a >{item.name}</a></li>
                                            </div>
                                        )
                                        )}
                                    </ul>
                                </div>
                                {/* <div className="sidebar__item">
                                    <h4>Price</h4>
                                    <div className="price-range-wrap">
                                        <div className="price-range ui-slider ui-corner-all ui-slider-horizontal ui-widget ui-widget-content"
                                            data-min="10" data-max="540">
                                            <div className="ui-slider-range ui-corner-all ui-widget-header"></div>
                                            <span tabIndex="0" className="ui-slider-handle ui-corner-all ui-state-default"></span>
                                            <span tabIndex="0" className="ui-slider-handle ui-corner-all ui-state-default"></span>
                                        </div>
                                        <div className="range-slider">
                                            <div className="price-input">
                                                <input type="text" id="minamount" />
                                                <input type="text" id="maxamount" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="sidebar__item sidebar__item__color--option">
                                    <h4>Colors</h4>
                                    {peoductList && peoductList.map((varColor, it) =>
                                        <div key={'item' + it}>
                                            {varColor.varients.map((selectcolor, i) => (
                                                <ul key={'color' + i}>
                                                    <li>{selectcolor.color}</li>
                                                </ul>
                                            )
                                            )}

                                        </div>
                                    )}
                                </div>
                                <div className="sidebar__item">
                                    <h4>Popular Size</h4>
                                    {peoductList && peoductList.map((varColor, varc) =>
                                        <div className="sidebar__item__size" key={'varc' + varc}>
                                            
                                            {varColor.varients.map((selectcolor, varientIndex) => (
                                                <ul key={'varient' + varientIndex}>
                                                    <li>{selectcolor.size}</li>
                                                </ul>
                                            )
                                            )}
                                        </div>
                                    )}
                                </div> */}
                            </div>
                        </div>
                        <div className="col-lg-9 col-md-7">
                            <div className="">
                                <div className="section-title product__discount__title">
                                    <h2>Sale Off</h2>
                                </div>
                            </div>
                            <div className="row">
                                {peoductList && peoductList?.map((item, i) =>
                                    <div key={'product'+i} className="col-lg-3 col-md-3 col-sm-4 col-6 p-2">
                                        <div className="product__item" onClick={(e)=>getDetails(e,item)}>
                                            <div className="product__item__pic set-bg" style={{ backgroundImage: 'url(' + baseUrl + '/file/load/' + item?.images[0] + ')' }}>
                                                {/* <ul className="product__item__pic__hover img_hover" >
                                                    {item.qty ? <li><a><i className="fa fa-minus-circle" onClick={(e) => {e.stopPropagation(); deleteCart(item, i,e) }}></i></a></li> : ''}
                                                    <span className='count_qty mr-2'>{item.qty}</span>
                                                    <li><a onClick={(e) => {e.stopPropagation();addtoCart(item, i,)}}>{item.qty ? <i className="fa fa-plus-circle" ></i> : <i className="fa fa-shopping-cart"></i>}</a></li>
                                                </ul> */}
                                            </div>
                                            <div className="product__item__text">
                                                <h6>{item?.name}</h6>
                                                <h5>{item?.price}</h5>
                                            </div>
                                        </div>
                                    </div>
                                )}
                                {!peoductList && <span style={{color:'red'}}>No data Found</span> }

                            </div>
                            {/* <div className="product__pagination">
                                <a href="#">1</a>
                                <a href="#">2</a>
                                <a href="#">3</a>
                                <a href="#"><i className="fa fa-long-arrow-right"></i></a>
                            </div> */}
                        </div>
                    </div>
                </div>
            </section>

        </div>
    );
}
export default Shop;