import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { baseUrl } from '../env';
import axios from 'axios';

const ModalPopup = (props) => {
  const { show, setShow,refresh,setRefresh } = props;

  const [dataValue, setDataValue] = useState({
    fullname: '',
    houseNo: '',
    street: '',
    location: '',
    pincode: '',
    landmark: '',
    city: '',
    state: '',
    country: '',
  })

  const handleInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setDataValue({ ...dataValue, [name]: value })
  }
  const handleSubmit = (e) => {
    let authToken = localStorage.getItem('token')
    e.preventDefault();
    let data = {
      fullname: dataValue.fullname,
      houseNo: dataValue.houseNo,
      street: dataValue.street,
      location: dataValue.location,
      pincode: dataValue.pincode,
      landmark: dataValue.landmark,
      city: dataValue.city,
      state: dataValue.state,
      country: dataValue.country
    };

    let config = {
      method: 'POST',
      url: baseUrl + 'customer/address/create',
      headers: {
        'token': authToken,
        "Content-Type": "application/json"
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        console.log(response.data);
        SuccessAddress()
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const SuccessAddress = () => {
    closeBtn()
    setRefresh(!refresh)
    setDataValue({
      fullname: '',
      houseNo: '',
      street: '',
      location: '',
      pincode: '',
      landmark: '',
      city: '',
      state: '',
      country: '',
    })
  }
  const closeBtn = () => {
    setShow(false)
  }
  return (
    <Modal show={show} onHide={closeBtn} centered>
      <Modal.Header>
        <Modal.Title>Add New Address</Modal.Title>
      </Modal.Header>
      <Form onSubmit={handleSubmit}>
        <Modal.Body>
          <div className='row'>
            <label className='col-4 mt-2'>Full Name</label>
            <div className='col-8 mt-2'>
              <Form.Control
                type="text"
                name='fullname'
                onChange={handleInput}
                value={dataValue.fullname}
                placeholder='Full Name*'
                required
              />
            </div>
          </div>
          <div className='row'>
            <label className='col-4 mt-2'>House No</label>
            <div className='col-8 mt-2'>
              <Form.Control
                type="text"
                name='houseNo'
                onChange={handleInput}
                value={dataValue.houseNo}
                placeholder='House No*'
                required
              />
            </div>
          </div>
          <div className='row'>
            <label className='col-4 mt-2'>Street</label>
            <div className='col-8 mt-2'>
              <Form.Control
                type="text"
                name='street'
                onChange={handleInput}
                value={dataValue.street}
                placeholder='Street*'
                required
              />
            </div>
          </div>
          <div className='row'>
            <label className='col-4 mt-2'>Location</label>
            <div className='col-8 mt-2'>
              <Form.Control
                type="text"
                name='location'
                onChange={handleInput}
                value={dataValue.location}
                placeholder='Location'
              />
            </div>
          </div>
          <div className='row'>
            <label className='col-4 mt-2'>Pincode</label>
            <div className='col-8 mt-2'>
              <Form.Control
                type="text"
                name='pincode'
                onChange={handleInput}
                value={dataValue.pincode}
                placeholder='Pincode*'
                required
              />
            </div>
          </div>
          <div className='row'>
            <label className='col-4 mt-2'>Landmark</label>
            <div className='col-8 mt-2'>
              <Form.Control
                type="text"
                name='landmark'
                onChange={handleInput}
                value={dataValue.landmark}
                placeholder='Land Mark'
              />
            </div>
          </div>
          <div className='row'>
            <label className='col-4 mt-2'>City</label>
            <div className='col-8 mt-2'>
              <Form.Control
                type="text"
                name='city'
                onChange={handleInput}
                value={dataValue.city}
                placeholder='City*'
                required
              />
            </div>
          </div>
          <div className='row'>
            <label className='col-4 mt-2'>State</label>
            <div className='col-8 mt-2'>
              <Form.Control
                type="text"
                name='state'
                onChange={handleInput}
                value={dataValue.state}
                placeholder='State*'
                required
              />
            </div>
          </div>
          <div className='row'>
            <label className='col-4 mb-2 mt-2'>Country</label>
            <div className='col-8 mb-2 mt-2'>
              <Form.Control
                type="text"
                name='country'
                onChange={handleInput}
                value={dataValue.country}
                placeholder='Country*'
                required
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button className="btn-danger" onClick={closeBtn}>
            Close
          </Button>
          <Button type='submit' className='btn-primary'>Submit</Button>
        </Modal.Footer>
      </Form>
    </Modal>
  );
};

export default ModalPopup;
