import React, { useState } from 'react'
import { Button, Form } from 'react-bootstrap'
import axios from 'axios'
import { baseUrl } from '../env';

function Address() {
  const [dataValue, setDataValue] = useState({
    fullname: '',
    houseNo: '',
    street: '',
    location: '',
    pincode: '',
    landmark: '',
    city: '',
    state: '',
    country: '',
  })

  const handleInput = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    setDataValue({ ...dataValue, [name]: value })
  }
  const handleSubmit = (e) => {
    let authToken = localStorage.getItem('token')
    e.preventDefault();
    let data = {
      fullname: dataValue.fullname,
      houseNo: dataValue.houseNo,
      street: dataValue.street,
      location: dataValue.location,
      pincode: dataValue.pincode,
      landmark: dataValue.landmark,
      city: dataValue.city,
      state: dataValue.state,
      country: dataValue.country
    };

    let config = {
      method: 'POST',
      url: baseUrl + 'customer/address/create',
      headers: {
        'token': authToken,
        "Content-Type": "application/json"
      },
      data: data
    };

    axios.request(config)
      .then((response) => {
        console.log('Response:-',response.data);
      })
      .catch((error) => {
        console.log('Error',error);
      });
  }
  return (
    <div>
      <section className="checkout spad">
        <div className="container">
          <div className="checkout__form">
            <h4>Billing Detailsssssssss</h4>
            <div className="row">
              <div className="col-lg-8 col-md-6">
              <Form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="checkout__input">
                      <p>FullName<span>*</span></p>
                      <input type="text"
                        name='fullname'
                        onChange={handleInput}
                        value={dataValue.fullname}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="checkout__input">
                      <p>House No<span>*</span></p>
                      <input type="text"
                        name='houseNo'
                        onChange={handleInput}
                        value={dataValue.houseNo}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="checkout__input">
                  <p>Street<span>*</span></p>
                  <input type="text"
                    name='street'
                    onChange={handleInput}
                    value={dataValue.street}
                    required
                  />
                </div>
                <div className='row'>
                  <div className="col-lg-6">
                    <div className="checkout__input">
                      <p>Location<span>*</span></p>
                      <input type="text"
                        name='location'
                        onChange={handleInput}
                        value={dataValue.location}
                        className="checkout__input__add"
                      />
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className="checkout__input">
                      <p>Town/City<span>*</span></p>
                      <input type="text"
                        name='city'
                        onChange={handleInput}
                        value={dataValue.city}
                        required
                      />
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className="checkout__input">
                      <p>State<span>*</span></p>
                      <input type="text"
                        name='state'
                        onChange={handleInput}
                        value={dataValue.state}
                        required
                      />
                    </div>
                  </div>
                  <div className='col-lg-6'>
                    <div className="checkout__input">
                      <p>Country<span>*</span></p>
                      <input type="text"
                        name='country'
                        onChange={handleInput}
                        value={dataValue.country}
                        required
                      />
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-6">
                    <div className="checkout__input">
                      <p>Postcode / ZIP<span>*</span></p>
                      <input type="text"
                        name='pincode'
                        onChange={handleInput}
                        value={dataValue.pincode}
                        required
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="checkout__input">
                      <p>Landmark</p>
                      <input type="text"
                        name='landmark'
                        onChange={handleInput}
                        value={dataValue.landmark}
                      />
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <button type="submit" className="site-btn">Submit</button>
                  </div>
                </div>
                </Form>
                {/* <div className="checkout__input__checkbox">
                    <label for="acc">
                      Create an account?
                      <input type="checkbox" id="acc" />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <p>Create an account by entering the information below. If you are a returning customer
                    please login at the top of the page</p>
                  <div className="checkout__input">
                    <p>Account Password<span>*</span></p>
                    <input type="text" />
                  </div>
                  <div className="checkout__input__checkbox">
                    <label for="diff-acc">
                      Ship to a different address?
                      <input type="checkbox" id="diff-acc" />
                      <span className="checkmark"></span>
                    </label>
                  </div>
                  <div className="checkout__input">
                    <p>Order notes<span>*</span></p>
                    <input type="text"
                      placeholder="Notes about your order, e.g. special notes for delivery." />
                  </div> */}
              </div>
              <div className="col-lg-4 col-md-6">
                <div className="checkout__order">
                  <h4>Your Order</h4>
                  <div className="checkout__order__products">Products <span>Total</span></div>
                  <ul>
                    <li>Vegetable’s Package <span>$75.99</span></li>
                    <li>Fresh Vegetable <span>$151.99</span></li>
                    <li>Organic Bananas <span>$53.99</span></li>
                  </ul>
                  <div className="checkout__order__subtotal">Subtotal <span>$750.99</span></div>
                  <div className="checkout__order__total">Total <span>$750.99</span></div>
                  {/* <div className="checkout__input__checkbox">
                      <label for="acc-or">
                        Create an account?
                        <input type="checkbox" id="acc-or" />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <p>Lorem ipsum dolor sit amet, consectetur adip elit, sed do eiusmod tempor incididunt
                      ut labore et dolore magna aliqua.</p>
                    <div className="checkout__input__checkbox">
                      <label for="payment">
                        Check Payment
                        <input type="checkbox" id="payment" />
                        <span className="checkmark"></span>
                      </label>
                    </div>
                    <div className="checkout__input__checkbox">
                      <label for="paypal">
                        Paypal
                        <input type="checkbox" id="paypal" />
                        <span className="checkmark"></span>
                      </label>
                    </div> */}
                  <button type="submit" className="site-btn">PLACE ORDER</button>
                </div>
              </div>
            </div>
            {/* </form> */}
          </div>
        </div>
      </section>
    </div>
  )
}

export default Address