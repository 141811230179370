import React, { useEffect } from 'react'
import axios from 'axios'
import { baseUrl } from '../env'
import { useState } from 'react'
import ModalPopup from './AddressModal'
import { Form } from 'react-bootstrap'
import Swal from 'sweetalert2'
import { useNavigate } from 'react-router-dom'

function GetAddress() {
    const navigate = useNavigate();
    const [show, setShow] = useState(false)
    const [getUserDetails, setUserDetails] = useState({})
    const [selectedAddress, setSelectedAddress] = useState({})
    const [refresh, setRefresh] = useState(false)
    const [value, setValue] = useState([])
    let token = localStorage.getItem('token')
    let vendorsId = localStorage.getItem('vendorId')
    let str = localStorage.getItem('cart-' + vendorsId)
    let cartList = str ? JSON.parse(str) : []

    useEffect(() => {
        let token = localStorage.getItem('token')
        axios.get(baseUrl + `customer/get`, {
            headers: {
                'Content-Type': 'application/x-www-form-urlencoded',
                token: token
            }
        })
            .then((res) => {
                res.data.data.addressList = res.data.data.address.map((address) => ({
                    address: address.houseNo + ' ' + address.street + ' ' + address.city + ' ' + address.state + ', ' + address.country + ', ' + address.pincode,
                    fullname: address.fullname,
                    pincode: address.pincode
                }));
                setUserDetails(res.data.data);
            })
            .catch((err) => {
                console.log('Errors', err);
            })

    }, [refresh])

    const AddressShow = () => {
        setShow(true)
    }

    const handleAddressSelection = (address) => {
        setSelectedAddress(address);
    };

    const handelChange = (e) => {
        const { name, value } = e.target
        setUserDetails({ ...getUserDetails, [name]: value })
    }

    // let cartValue = localStorage.getItem('cart')
    // let cartData = JSON.parse(cartValue)

    const submitVal = (e) => {
        e.preventDefault()
        let data = {
            name: selectedAddress?.fullname,
            mobile: getUserDetails?.mobile,
            address: selectedAddress?.address,
            vendorId: vendorsId,
            products: value.map(item => {
                let obj = { ...item }
                delete obj.offerPrice
                delete obj.createdBy
                delete obj.updatedAt
                delete obj.createdAt
                delete obj.__v
                return obj
            })
        }

        let config = {
            method: 'POST',
            url: baseUrl + 'order/create',
            headers: {
                'token': token,
                "Content-Type": "application/json"
            },
            data: data
        };

        axios.request(config)
            .then((response) => {
                Swal.fire({
                    title: 'Success !',
                    text: 'Your Order Placed Successfully!',
                    icon: 'success',
                    timer: 3000,
                    showConfirmButton: false,
                });
                localStorage.removeItem('cart-' + vendorsId)
                const event = new Event('localstorage');
                event.key = 'test';
                event.value = 'hello';
                document.dispatchEvent(event);
                navigate('/')
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Error !',
                    text: 'Error while placing order',
                    icon: 'error',
                    timer: 3000,
                    showConfirmButton: false,
                });
            });
    }
    useEffect(() => {
        calculation()
    }, [])

    const [totalObj, setTotalObj] = useState({
        total: 0,
        ftotal: 0
    })

    // const calculation=()=>{
    //     totalObj.total=0
    //     totalObj.ftotal=0
    //     cartList.forEach(item => {
    //         // item.fprice=item.price
    //         // item.offerPrice.forEach(price=>{
    //         //     if(price.qty<=item.qty){
    //         //         item.price=price.price
    //         //     }
    //         // })
    //         let pc=item.price
    //         item.price=item.fprice
    //         item.price=pc
    //         totalObj.total+=item.qty*item.price
    //         totalObj.ftotal+=item.qty*item.fprice
    //         setTotalObj({...totalObj})
    //    });
    //    setValue([...cartList])
    // }
    const calculation = () => {
        totalObj.total = 0
        totalObj.ftotal = 0
        cartList.forEach(item => {
            item.fprice = item.price
            item.offerPrice.forEach(price => {
                if (price.qty <= item.qty) {
                    item.fprice = item.fprice-item.fprice*price.price/100
                }
            })
            totalObj.total += item.qty * item.price
            totalObj.ftotal += item.qty * item.fprice
            setTotalObj({ ...totalObj })
        });

        setValue([...cartList])
    }

    return (
        <>
            <section className="checkout spad">
                <div className="container">
                    <div className="checkout__form">
                        <h4>Order Details</h4>
                        <Form onSubmit={submitVal}>
                            <div className="row">
                                <div className="col-lg-8 col-md-6">
                                    {/* <Form onSubmit={handleSubmit}> */}
                                    <div className="row">
                                        <div className="col-lg-6">
                                            <div className="checkout__input">
                                                <p>FullName<span>*</span></p>
                                                <input
                                                    type="text"
                                                    name='name'
                                                    defaultValue={selectedAddress?.fullname}
                                                    onChange={handelChange}
                                                    placeholder='Name*'
                                                    required
                                                />
                                            </div>
                                        </div>
                                        <div className="col-lg-6">
                                            <div className="checkout__input">
                                                <p>Mobile No<span>*</span></p>
                                                <input type="text"
                                                    defaultValue={getUserDetails?.mobile}
                                                    name='mobile'
                                                    onChange={handelChange}
                                                    placeholder='Mobile'
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="checkout__input">
                                        <p>Address<span>*</span></p>
                                        <input type="text"
                                            name='address'
                                            defaultValue={selectedAddress?.address}
                                            onChange={handelChange}
                                            placeholder='Address'
                                            // disabled
                                            required
                                        />
                                    </div>
                                    {getUserDetails?.addressList?.map((addressObj, i) => (
                                        <div key={i} style={{ cursor: 'pointer' }} onClick={() => handleAddressSelection(addressObj)} >
                                            <input type='radio' name='address' style={{ cursor: 'pointer' }} />
                                            {addressObj.address}
                                        </div>
                                    ))}
                                    <span className='Addressbtn' onClick={AddressShow}> + Add Address </span>
                                    <ModalPopup show={show} setShow={setShow} setRefresh={setRefresh} refresh={refresh} />
                                </div>
                                <div className="col-lg-4 col-md-6">
                                    <div className="checkout__order">
                                        <h4>Your Order</h4>
                                        <div className="checkout__order__products">Products <span>Total</span></div>
                                        {value?.length > 0 && value?.map((data, i) =>
                                            <ul key={i}>
                                                <li>{data?.name} <span> ₹{data?.qty * data?.fprice}</span></li>
                                            </ul>
                                        )}
                                        <div className="checkout__order__subtotal">Subtotal <span>₹{totalObj.total} </span></div>
                                        <div className="checkout__order__total">Total <span>₹{totalObj.ftotal}</span></div>
                                        <button type="submit" className="site-btn">PLACE ORDER</button>
                                    </div>
                                </div>
                            </div>
                        </Form>
                    </div>
                </div>
            </section>
        </>
    )
}

export default GetAddress