import React from 'react'

function NotFound() {
  return (
    <div style={{
      fontSize: '50px',
      color: 'red',
      textAlign: 'center'
    }}>Invalid Url!</div>
  )
}

export default NotFound