import React, { useEffect, useState } from 'react'
import { baseUrl } from '../env';
import axios from 'axios';
const status = {
  Pending: 1,
  Accepted: 2,
  Packed: 3,
  Shipped: 4,
  Delivered: 5,
}

function OrderList() {

  const [orderStatus, setOrderStatus] = useState([])
  useEffect(() => {
    let token = localStorage.getItem('token')
    axios.get(baseUrl + `order/get`, {
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
        token: token
      }
    })
      .then((res) => {
        setOrderStatus(res.data.data)
      })
      .catch((err) => {
        console.log('Errors', err);
      })
  }, [])

  // let totalAmount=orderStatus?.products?.reduce((a,b)=>a+b.qty*b.fprice,0)
  let amount = orderStatus.map(data => data.products)
  return (
    <div>
     
      <div className="container px-1 px-md-4 py-5 mx-auto" >
        {orderStatus?.map((data, i) =>
          <div className="card" key={i}>
            <div className="row d-flex justify-content-between px-3 top">
              <div className="d-flex">
                <h5>Items: <span className="text-primary font-weight-bold">{data?.products?.length}</span></h5>
              </div>
              <div className="d-flex">
                <h5>Name: <span className="text-primary font-weight-bold">{data?.name}</span></h5>
              </div>
              <div className="d-flex">
                <h5>Mobile No: <span className="text-primary font-weight-bold">{data?.mobile}</span></h5>
              </div>
              <div className="d-flex">
                <h5>Total Amount: <span className="text-primary font-weight-bold">₹678</span></h5>
              </div>
            </div>
            <div className="row d-flex justify-content-center">
              <div className="col-12 col_row">
                <ul id="progressbar" className="text-center">
                  <li className={status[data.status] >= 1 ? 'step0 active' : 'step0'}>
                    <img className="icon" src="https://i.imgur.com/9nnc9Et.png" />
                    <p className="font-weight-bold">Order<br />Process</p></li>
                  <li className={status[data.status] >= 2 ? 'step0 active' : 'step0'}>
                    <img className="icon" src="https://i.imgur.com/u1AzR7w.png" />
                    <p className="font-weight-bold">Order<br />Accepted</p></li>
                  <li className={status[data.status] >= 3 ? 'step0 active' : 'step0'}>
                    <img className="icon" src="https://i.imgur.com/TkPm63y.png" />
                    <p className="font-weight-bold">Order<br />Packed</p></li>
                  <li className={status[data.status] >= 4 ? 'step0 active' : 'step0'}>
                    <img className="icon" src="https://i.imgur.com/TkPm63y.png" />
                    <p className="font-weight-bold">Order<br />Shipped</p></li>
                  <li className={status[data.status] >= 5 ? 'step0 active' : 'step0'}>
                    <img className="icon" src="https://i.imgur.com/HdsziHP.png" />
                    <p className="font-weight-bold">Order<br />Delivered</p></li>
                </ul>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>

  )
}

export default OrderList