import React from 'react'
import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom';
import Footer from '../component/Footer';
import Header from '../component/Header';
import Shop from '../component/Shop';
import Cart from '../pages/Cart';
import Address from '../pages/Address';
import GetAddress from '../pages/GetAddress';
import OrderList from '../pages/OrderList';
import ProductDetails from '../pages/ProductDetails';
import ContactUs from '../pages/ContactUs';
import NotFound from '../component/NotFound';


function AllRoute() {
    return (
        <HashRouter>
            <Header />
            <Routes>
                <Route exact path='/:vendorid' element={<Shop />} />
                <Route exact path='/' element={<Shop />} />
                <Route exact path="/cart" element={<Cart />} />
                <Route exact path='/address' element={<Address />} />
                <Route exact path='/contactus' element={<ContactUs />} />
                <Route exact path='/productdetails/:id' element={<ProductDetails />} />
                <Route exact path='/getaddress' element={<GetAddress />} />
                <Route exact path='/orderlist' element={<OrderList />} />
                <Route exact path='/urlnotfound' element={<NotFound />} />
            </Routes>
            <Footer />
        </HashRouter>

    )
}

export default AllRoute;
