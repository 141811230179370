import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";

import './Header.css';
import LoginModal from "../pages/LoginModal";

function Header() {
    const navigate = useNavigate()

    const [count, setCount] = useState(0)
    const [totalAmount, setTotalAmount] = useState(0)
    const [show, setShow] = useState(false);
    const [mobileMenuOpen, setMobileMenuOpen] = useState(false);


    const handleShow = () => {
        setShow(!show)
    };

    const getItemCount = () => {
        const vendorId = localStorage.getItem('vendorId')
        const str = localStorage.getItem('cart-' + vendorId);
        let cartlist = str ? JSON.parse(str) : [];
        let total = 0
        let tamount=0
        let famount=0
        cartlist.map(item => {
            if (item.qty) {
                total += Number(item.qty)
                item.fprice=item.price
                item.offerPrice.forEach(price=>{
                    if(price.qty<=item.qty){
                        item.fprice=price.price
                    }
                })
                tamount+=item.qty*item.price
                famount+=item.qty*item.fprice
            }
        })
        setTotalAmount(famount)
        setCount(total)
    }

    const getEvent = (a) => {
        document.addEventListener('localstorage', () => {
            getItemCount()
        })

    }
    useEffect(() => {
        getItemCount()
        getEvent()
    }, [])
    let Token = localStorage.getItem('otptoken')

    const logOut = () => {
        localStorage.clear('token')
        navigate('/')
    }

    const toggleMobileMenu = () => {
        setMobileMenuOpen(!mobileMenuOpen);
    };
    return (
        <div >
            <div className={`humberger__menu__overlay ${mobileMenuOpen === true?'active':''}`}></div>
            <div className={`humberger__menu__wrapper ${mobileMenuOpen === true?'show__humberger__menu__wrapper':''}`}>
                <span onClick={toggleMobileMenu} style={{marginLeft:'230px'}}>X</span>
                <div className="humberger__menu__logo">
                    <a href="#"><img src="img/cart-2.jpg" alt="" /></a>
                </div>
                {/* <div className="humberger__menu__cart">
                    <ul>
                        <li><a href="#"><i className="fa fa-heart"></i> <span>1</span></a></li>
                        <li><Link to="/cart"><i className="fa fa-shopping-bag"></i> {count ? <span>{count}</span> : ''}</Link></li>
                    </ul>
                    <div className="header__cart__price">item: <span>₹{totalAmount}</span></div>
                </div> */}
                <div className="humberger__menu__widget">
                    {/* <div className="header__top__right__language">
                        <img src="img/language.png" alt="" />
                        <div>English</div>
                        <span className="arrow_carrot-down"></span>
                        <ul>
                            <li><a href="#">Spanis</a></li>
                            <li><a href="#">English</a></li>
                        </ul>
                    </div> */}
                    <div className="header__top__right__auth">
                        <i className="fa fa-user"> </i> {!Token ? <span onClick={handleShow} className="loginbtn">Login</span> : <span onClick={logOut} className="loginbtn">Logout</span>}
                    </div>
                </div>
                {/* <nav className="humberger__menu__nav mobile-menu">
                    <ul>
                        <li><a href="/">Shop</a></li>
                        <li><a href="/">Shop Details</a></li>
                        <li><a href="/cart">Shoping Cart</a></li>
                        <li><a href="./contact.html">Contact</a></li>
                    </ul>
                </nav> */}
                <div id="mobile-menu-wrap"></div>
                <div className="header__top__right__social">
                    <ul style={{listStyle:'none'}}>
                    <li className="active"><Link to='/' onClick={toggleMobileMenu}>Shop</Link></li>
                    <li><Link to="/orderlist" onClick={toggleMobileMenu}>Orders</Link></li>
                    {/* <li><Link to="/cart" onClick={toggleMobileMenu}>Shoping Cart</Link></li>
                    <li><Link to="/getaddress" onClick={toggleMobileMenu}>Check Out</Link></li> */}
                    <li><Link to="/contactus" onClick={toggleMobileMenu}>Contact</Link></li>
                    </ul>
                </div>
                <div className="humberger__menu__contact">
                    <ul>
                        <li><i className="fa fa-envelope"></i> pickups@gmail.com</li>
                        {/* <li>Free Shipping for all Order of ₹1000</li> */}
                    </ul>
                </div>
            </div>

            <header className="header">
                <div className="header__top">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6">
                                <div className="header__top__left">
                                    <ul>
                                        <li><i className="fa fa-envelope"></i> pickups@gmail.com</li>
                                        {/* <li>Free Shipping for all Order of ₹1000</li> */}
                                    </ul>
                                </div>
                            </div>
                            <div className="col-lg-6">
                                <div className="header__top__right">
                                    <div className="header__top__right__social">
                                        <Link to="https://www.facebook.com/"><i className="fa fa-facebook"></i></Link>
                                        <Link to="https://twitter.com"><i className="fa fa-twitter"></i></Link>
                                        <Link to="https://www.linkedin.com"><i className="fa fa-linkedin"></i></Link>
                                    </div>
                                    <div className="header__top__right__auth">
                                        <i className="fa fa-user"> </i> {!Token ? <span onClick={handleShow} className="loginbtn">Login</span> : <span onClick={logOut} className="loginbtn">Logout</span>}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="row">
                        <div className="col-lg-3">
                            <div className="header__logo">
                                <a href="./index.html"><img src="img/logo.png" alt="" /></a>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <nav className="header__menu">
                                <ul>
                                    <li className="active"><Link to='/'>Shop</Link></li>
                                    <li><Link to="/orderlist">Orders</Link></li>
                                    {/* <li><Link to="/cart">Shoping Cart</Link></li>
                                    <li><Link to="/getaddress">Check Out</Link></li> */}
                                    <li><Link to="/contactus">Contact</Link></li>
                                </ul>
                            </nav>
                        </div>
                        <div className="col-lg-3">
                            <div className="header__cart">
                                <ul>
                                    <li><a href="#"><i className="fa fa-heart"></i> <span>1</span></a></li>
                                    <li><Link to="/cart"><i className="fa fa-shopping-bag"></i> {count ? <span>{count}</span> : ''}</Link></li>
                                </ul>
                                <div className="header__cart__price">item: <span>₹{totalAmount}</span></div>
                            </div>
                        </div>
                    </div>
                    <div className="humberger__open" onClick={toggleMobileMenu}>
                        <i className="fa fa-bars"></i>
                    </div>
                </div>
            </header>
            <LoginModal setShow={setShow} show={show} />
        </div>
    );
}

export default Header;