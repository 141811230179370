import axios from "axios";
import { baseUrl } from "../env";

export const getApi = async (id) => {
    return await axios.get(baseUrl + 'catalouge/share/'+id)
}

export const loginUser = async () => {
   return await axios.post(baseUrl + 'auth/login');
}

export const getProductById=async(id)=>{
    return await axios.get(baseUrl + 'product/getbyid/'+id)
}