import React, { useState } from 'react'
import { Button, Form, Modal } from 'react-bootstrap'
import axios from 'axios';
import { baseUrl } from '../env';
import OtpVarificationModal from './OtpVarificationModal';

function LoginModal(props) {
    const { show, setShow } = props;
    const [visible,setVisible]=useState(false)
    const [formData, setFormData] = useState({
        mobile: ''
    });
    const handleShow = () => {
        setVisible(true)
    };

    const handleInputChange = (event) => {
        const name = event.target.name;
        const value = event.target.value;
        setFormData({ ...formData, [name]: value })
    };
    const handleClose = () => setShow(false);
    const handleSubmit = (e) => {
        e.preventDefault();
        let data = {
            username: formData.mobile
          };
          
          let config = {
            method: 'POST',
            url: baseUrl+'auth/login',
            headers: { 
                'Content-Type': 'application/x-www-form-urlencoded'
              },
            data : data
          };
          axios.request(config)
          .then((response) => {
            localStorage.setItem('otptoken',response.data.data.token)
            handleShow()
            handleClose()
          })
          .catch((error) => {
            console.log('Errors:-',error);
          });
    }
    return (
        <div>
            <Modal show={show} >
                <Modal.Header>
                    <Modal.Title>Login Form</Modal.Title>
                </Modal.Header>
                <Form onSubmit={handleSubmit}>
                    <Modal.Body>
                        <Form.Group controlId="formName">
                            <Form.Control type="text"
                                placeholder="Enter Your Email"
                                name='mobile'
                                value={formData.mobile}
                                onChange={handleInputChange}
                                required
                            />
                        </Form.Group>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={handleClose}>
                            Close
                        </Button>
                        <Button variant="primary"
                            type="submit">
                            Login
                        </Button>
                    </Modal.Footer>
                </Form>
            </Modal>
            <OtpVarificationModal visible={visible} setVisible={setVisible} />
        </div>
    )
}

export default LoginModal